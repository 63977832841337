<template>
  <client-only>
    <div v-if="popup.popup_trigger === true">
      <Popupgesloten v-if="popupopen === true" @close="popupopen = false"/>
    </div>
    <div class="flex justify-between flex-col min-h-screen">
      <div class="py-1 text-center text-white bg-primary-600">
        <p class="text-xs md:text-base">{{ navigatieData.tekst_bezorgen ?? 'Woensdag voor 23:59 besteld, donderdag of vrijdag in huis!' }}</p>
      </div>
      <AlgemeenNavigation class="md:flex hidden"/>
      <AlgemeenMobilemenu class="md:hidden"/>
      <div class="flex-1">
        <slot/>
      </div>
      <HerbruikbaarFullunderline/>
      <AlgemeenFooting/>
    </div>
  </client-only>
</template>
<script setup>
const {getItems} = useDirectusItems();

const popupopen = ref(false)

const popup = await getItems({
  collection: 'popup_gesloten',
  fields: ['*']
})

const navigatieData = await getItems({
  collection: "header"
});

onMounted(() => {
  if (process.client) {
    const nu = new Date();
    const local = localStorage.getItem("popupopen")
    if (local == undefined || nu.getTime() > new Date(parseInt(local)).getTime()) {
      popupopen.value = true
      nu.setHours(nu.getHours() + 1)
      localStorage.setItem("popupopen", nu.getTime())
    }
  }
})
</script>